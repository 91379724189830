import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Chip } from '@mui/material'

import languageService from '../../../../../services/LanguageService'
import { TagList } from '../../../../feature/components/TagList/TagList'
import { useLiveEventTagGroupsWithTags } from '../../../hooks/useLiveEventTagGroups'
import { LiveEventCalendarAdditionalDataId } from '../../../types/LiveEventAdditionalCalendarData'
import { LiveEventAppearance } from '../../../types/LiveEventAppearance'
import { LiveEventDuration } from '../../../types/LiveEventDuration'
import { LiveEventTrackerTab } from '../../../types/LiveEventTrackerTab'
import { TLiveEventsCommonFilters, LiveEventsCommonFiltersData } from '../../../types/LiveEventsCommonFilters'
import { resolveAdditionalDataDisabledState } from '../../../utils/utils'
import LiveEventsSecondaryTaxonomyTagList from '../../LiveEventsSecondaryTaxonomyTagList/LiveEventsSecondaryTaxonomyTagList'

/**
 * Component for displaying the common LET filters that are currently active
 */
type ActiveCommonFiltersProps = {
  filters: TLiveEventsCommonFilters
  filtersData: LiveEventsCommonFiltersData
}

export const ActiveCommonFilters: FC<ActiveCommonFiltersProps> = ({ filters, filtersData }) => {
  const { t } = useTranslation()
  const { subpage = LiveEventTrackerTab.Feed } = useParams<{ subpage: LiveEventTrackerTab }>()
  const { data: liveEventTagGroupsWithTags } = useLiveEventTagGroupsWithTags()

  return (
    <>
      {(Object.keys(filters.additionalDatas) as LiveEventCalendarAdditionalDataId[]).map((id) => {
        const disabled = resolveAdditionalDataDisabledState(subpage, id)
        return (
          <Chip
            key={id}
            label={t(filtersData.additionalDatas.find((additionalData) => additionalData.id === id)?.name || '')}
            disabled={disabled}
            color="secondary"
          />
        )
      })}
      {filters.liveEventTags.length > 0 && (
        <TagList selectedTags={filters.liveEventTags} tagGroups={liveEventTagGroupsWithTags} variant="small" selectedOnly hideGroupTitles />
      )}
      {filters.liveEventSecondaryTags.length > 0 && (
        <div className="secondaryTagList">
          <LiveEventsSecondaryTaxonomyTagList tags={filters.liveEventSecondaryTags.map((tag) => tag.id)} justifyContent="flex-start" />
        </div>
      )}
      {Object.keys(filters.motivations).map((motivationType) => {
        return <Chip key={motivationType} label={languageService.getTranslation('motivations', motivationType)} />
      })}
      {Object.keys(filters.liveEventDurations).map((id) => {
        const duration = filtersData.liveEventDurations.find((duration) => duration.id === id) as LiveEventDuration
        const label = duration.start > 1 ? `${duration?.name} ${t('common:days')}` : `${duration?.name} ${t('common:day')}`
        return <Chip key={id} label={label} color="secondary" />
      })}
      {Object.keys(filters.liveEventAppearances).map((id) => {
        const appearance = filtersData.liveEventAppearances.find((appearance) => appearance === id) as LiveEventAppearance
        return <Chip key={id} label={t('live-events:appearance_event_type_' + appearance)} color="secondary" />
      })}
    </>
  )
}
