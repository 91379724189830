import { add, endOfDay, startOfDay } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Button, Divider, Grid } from '@mui/material'

import { Game } from '../..'
import { useGetTrackedGameQuery } from '../../../../api/core'
import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { GamePerformaceDialogData, GamePerformanceDialog } from '../../../../components/GamePerformanceDialog/GamePerformanceDialog'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { PerformanceChartV2DataType } from '../../../../components/PerformanceChartV2/PerformanceChartV2DataType'
import { getIntervalByGranularity } from '../../../../helpers/date'
import { dayMilliseconds } from '../../../../helpers/dayMilliseconds'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { useDebouncedResize } from '../../../../hooks/useDebouncedResize'
import {
  maxDayDifferenceForDateRange,
  maxLiveEventsCalendarZoomInMilliseconds,
  minLiveEventsCalendarZoomInMilliseconds,
  resolveYAxisDataTypes,
} from '../../../../pages/LiveEventsTrackerPage/LiveEventsTrackerPage'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import utilsService from '../../../../services/UtilsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useLiveEventsTrackerAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { Analysis } from '../../../analysis/types/Analysis'
import { LiveEventInstanceTableContainer } from '../../../live-events/components/LiveEventInstanceTable/LiveEventInstanceTableContainer'
import { LiveEventsCalendarByGameContainer } from '../../../live-events/components/LiveEventsCalendar/LiveEventsCalendarByGameContainer/LiveEventsCalendarByGameContainer'
import { LiveEventsFilters, LiveEventsFiltersConfig } from '../../../live-events/components/LiveEventsFilters/LiveEventsFilters'
import {
  defaultTrackedEventsStartTimestamp,
  lockedLiveEventsTrackerEndTimestamp,
  lockedLiveEventsTrackerStartTimestamp,
} from '../../../live-events/const/const'
import { EventDialogTab } from '../../../live-events/hooks/useEventDialogTabs'
import { useLiveEventModal } from '../../../live-events/hooks/useLiveEventModal'
import { useLiveEventsAnalystNotesModal } from '../../../live-events/hooks/useLiveEventsAnalystNotesModal'
import { useLiveEventsAnalystOverviewModal } from '../../../live-events/hooks/useLiveEventsAnalystOverviewModal'
import { useLiveEventsCalendarGroupClickHandler } from '../../../live-events/hooks/useLiveEventsCalendarGroupClickHandler'
import { onlyMixpanelTrackingServicesToExclude } from '../../../live-events/hooks/useLiveEventsTrackerAnalyticsEvents'
import { useLiveEventsTrackingSearchParams } from '../../../live-events/hooks/useLiveEventsTrackingSearchParams'
import { useDefaultLiveEvents, useFilteredTrackedGamesEvents } from '../../../live-events/hooks/useTrackedGamesEvents'
import { selectHighlightedEvent } from '../../../live-events/slices/liveEventModalSlice'
import { visibleGameCalendarsChanged } from '../../../live-events/slices/liveEventsCalendarsSlice'
import { CalendarTimeRanges } from '../../../live-events/types/Calendar'
import {
  AnalystReviewTimelineItem,
  LiveEventsCalendarTimelineType,
  PerformanceEffectTimelineItem,
  TrackingEventTimelineItem,
} from '../../../live-events/types/LiveEvents'
import { TLiveEventsCommonFilters } from '../../../live-events/types/LiveEventsCommonFilters'
import { PerformanceChangesByOptionValue } from '../../../live-events/types/PerformanceChangesByOption'
import { PerformanceEffectType } from '../../../live-events/types/PerformanceEffect'
import { TrackedGame } from '../../../live-events/types/TrackedGame'
import { getLiveEventCalendarAdditionalDatas } from '../../../live-events/utils/utils'
import { useCurrentMarket } from '../../../markets'
import { GranularityValue } from '../../../revenue-and-downloads/types/Filters'
import './GameLiveEvents.scss'

const emptyArray = [] as any[]

interface Props {
  game: Game
  analysis: Analysis | undefined
}

const GameLiveEvents: React.FC<Props> = ({ game, analysis }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Live Events', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis?.id, game.id, game.resolvedName])

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const userLanguage = useCurrentUserLanguage()
  const { currentMarketIso } = useCurrentMarket()
  const { parsedParams, setSearchParams } = useLiveEventsTrackingSearchParams()
  const [eventNameSearchValue] = useState<string>('')
  const [calendarTimeRanges, setCalendarTimeRanges] = useState<CalendarTimeRanges>({})
  const highlightedEventId = useAppSelector(selectHighlightedEvent)
  const [gamePerformanceDialogData, setGamePerformanceDialogData] = useState<GamePerformaceDialogData>()
  const [selectedPerformanceChartInterval, setSelectedPerformanceChartInterval] = useState<Interval>()
  const handleChartClick = setSelectedPerformanceChartInterval
  const debouncedResize = useDebouncedResize(50)
  const handleCalendarRerender = useCallback(debouncedResize, [debouncedResize])
  const handleCalendarEventGroupClick = useLiveEventsCalendarGroupClickHandler()

  const { showModal: showEventModal } = useLiveEventModal()
  const { showModal: showAnalystNotesModal } = useLiveEventsAnalystNotesModal()
  const { showModal: showAnalystOverviewModal } = useLiveEventsAnalystOverviewModal()

  const [commonFilters, setCommonFilters] = useState<TLiveEventsCommonFilters>({
    liveEventTags: [],
    liveEventSecondaryTags: [],
    additionalDatas: {},
    liveEventDurations: {},
    liveEventAppearances: {},
    motivations: {},
  })

  const { currentData: trackedGame, isFetching: isLoadingTrackedGame } = useGetTrackedGameQuery({ gameId: game.id, userLanguage }, { skip: !game.isTracked })

  const trackedGameArray = useMemo(() => {
    if (trackedGame && trackedGame.allowed) {
      return [trackedGame]
    }

    return []
  }, [trackedGame])

  const gameIdArray = useMemo(() => {
    if (trackedGame && trackedGame.allowed) {
      return [trackedGame.game.id]
    }

    return []
  }, [trackedGame])

  const trackedEvents = useDefaultLiveEvents({ trackedGameIds: gameIdArray })

  const filters = useMemo(
    () => ({
      selectedLiveEventTags: commonFilters.liveEventTags,
      selectedLiveEventSecondaryTags: commonFilters.liveEventSecondaryTags,
      selectedLiveEventsDurationIdsMap: commonFilters.liveEventDurations,
      selectedLiveEventsAppearanceIdsMap: commonFilters.liveEventAppearances,
      selectedMotivationsIdsMap: commonFilters.motivations,
      performanceEffectThreshold: parsedParams.performanceEffectThreshold,
      performanceChangesBy: parsedParams.performanceChangesBy,
      eventNameSearchValue,
    }),
    [
      commonFilters.liveEventTags,
      commonFilters.liveEventSecondaryTags,
      commonFilters.liveEventDurations,
      commonFilters.liveEventAppearances,
      commonFilters.motivations,
      parsedParams.performanceEffectThreshold,
      parsedParams.performanceChangesBy,
      eventNameSearchValue,
    ]
  )

  const filteredTrackingEventsByGame = useFilteredTrackedGamesEvents({
    data: trackedEvents,
    ...filters,
  })

  // resolve configuration for filters based on selected tab
  const filterConfig: LiveEventsFiltersConfig = useMemo(() => {
    const config: LiveEventsFiltersConfig = {
      performanceChangesByDisabled: false,
      thresholdDisabled: false,
      sortGamesByDisabled: false,
    }

    return config
  }, [])

  const calendarAdditionalDatas = useMemo(() => {
    return getLiveEventCalendarAdditionalDatas()
  }, [])

  const dateRange = useMemo(
    () => ({
      fromDate: parsedParams.dateFrom ? new Date(parsedParams.dateFrom) : undefined,
      toDate: parsedParams.dateTo ? new Date(parsedParams.dateTo) : undefined,
    }),
    [parsedParams.dateFrom, parsedParams.dateTo]
  )

  const gamePerformanceDialogSelectedUpdates = useMemo(() => {
    return selectedPerformanceChartInterval?.start ? [{ timestamp: selectedPerformanceChartInterval?.start }] : []
  }, [selectedPerformanceChartInterval?.start])

  const handleCalendarTimeRangeChange = useCallback(
    (calendarId: string | number, timeStart: number, timeEnd: number) => {
      // update time range only if it's within allowed limits
      if (timeEnd - timeStart <= maxLiveEventsCalendarZoomInMilliseconds + dayMilliseconds) {
        setCalendarTimeRanges((current) => {
          const newRanges = { ...current }
          if (newRanges[calendarId]) {
            newRanges[calendarId] = { timeStart, timeEnd }
          } else {
            newRanges[calendarId] = { timeStart: parsedParams.dateFrom, timeEnd: parsedParams.dateTo }
          }

          return newRanges
        })
      }
    },
    [parsedParams.dateFrom, parsedParams.dateTo]
  )

  const handleGamePerformanceDialogOpen = useCallback(
    (trackedGame: TrackedGame) => {
      setGamePerformanceDialogData({
        appId: trackedGame.game.appId,
        gameName: trackedGame.game.resolvedName,
        marketIso: currentMarketIso,
        initialYAxisLeftConfig: { dataType: PerformanceChartV2DataType.Revenue },
        initialYAxisRightConfig: { dataType: PerformanceChartV2DataType.Downloads },
        initialGranularity: GranularityValue.Week,
      })
    },
    [currentMarketIso]
  )

  const handlePerformanceDialogClose = useCallback(() => {
    setGamePerformanceDialogData(undefined)
    setSelectedPerformanceChartInterval(undefined)
  }, [])

  const getPerformanceDialogInstanceTableInterval = useCallback(
    (granularity: GranularityValue) => {
      return selectedPerformanceChartInterval
        ? selectedPerformanceChartInterval
        : gamePerformanceDialogData?.highlightedTimestamp
        ? getIntervalByGranularity(granularity, gamePerformanceDialogData?.highlightedTimestamp)
        : undefined
    },
    [gamePerformanceDialogData?.highlightedTimestamp, selectedPerformanceChartInterval]
  )

  const handleCalendarEventItemClick = useCallback(
    (clickedItem: TrackingEventTimelineItem | PerformanceEffectTimelineItem | AnalystReviewTimelineItem) => {
      switch (clickedItem.type) {
        case LiveEventsCalendarTimelineType.TrackingEvent:
        case LiveEventsCalendarTimelineType.TrackingEventWithGame:
          const item: TrackingEventTimelineItem = clickedItem as TrackingEventTimelineItem

          showEventModal({
            trackedGameId: item.trackedGame.game.id,
            eventTypeId: item.trackingEvent.typeId,
            eventId: item.trackingEventId,
            tab: EventDialogTab.Description,
          })
          break

        case LiveEventsCalendarTimelineType.PerformanceEffect:
          const data = {
            gameName:
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.names['us'] ||
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.resolvedName,
            gameAppId: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.appId,
            gameConventionalSubgenre: languageService.getTranslation(
              'conventionalSubgenres',
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.conventionalSubgenreId
            ),
            source: `${clickedItem.group} Change Click`,
          }

          analyticsService.trackEvent('Live Events Tracker: Opened Game Statistics Graph', {
            data,
            serviceToExclude: onlyMixpanelTrackingServicesToExclude,
          })

          const yAxisDataTypes = resolveYAxisDataTypes((clickedItem as PerformanceEffectTimelineItem).group)
          setSelectedPerformanceChartInterval({ start: clickedItem.start_time, end: clickedItem.end_time })
          setGamePerformanceDialogData({
            appId: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.appId,
            gameName: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.resolvedName,
            marketIso: currentMarketIso,
            highlightedTimestamp: clickedItem.start_time,
            initialYAxisLeftConfig: { dataType: yAxisDataTypes[0] },
            initialYAxisRightConfig: { dataType: yAxisDataTypes[1] },
            initialGranularity:
              (clickedItem as PerformanceEffectTimelineItem).group === PerformanceEffectType.MAU ? GranularityValue.Month : GranularityValue.Day,
          })
          break
        case LiveEventsCalendarTimelineType.AnalystReview:
          const analystReviewItem: AnalystReviewTimelineItem = clickedItem as AnalystReviewTimelineItem
          showAnalystNotesModal({
            trackedGameId: analystReviewItem.review.gameId,
            commentId: analystReviewItem.review.id,
          })
      }
    },
    [currentMarketIso, showAnalystNotesModal, showEventModal]
  )

  const handleOverviewTrackedGameChanged = useCallback(
    (trackedGame: TrackedGame) => {
      showAnalystOverviewModal({
        trackedGameId: trackedGame.game.id,
      })
    },
    [showAnalystOverviewModal]
  )

  // make sure selected date starts from start of day and ends to end of day
  const handleDateRangeChange = useCallback(
    (value?: DateRangeValue) => {
      let fromDate = value?.fromDate
      let toDate = value?.toDate

      // Handle fromDate and toDate setting to have maxDayDifferenceForDateRange
      if (value && value.fromDate && value.toDate) {
        const dayDifference = utilsService.getDayDifferenceFromTwoDates(value.fromDate.getTime(), value.toDate.getTime())
        if (dayDifference > maxDayDifferenceForDateRange) {
          if (new Date(parsedParams.dateFrom as number).getTime() === fromDate?.getTime() && toDate) {
            // toDate changed changed
            fromDate = new Date(toDate.getTime())
            fromDate.setHours(0, 0, 0, 0)
            fromDate.setDate(fromDate.getDate() - maxDayDifferenceForDateRange)
          } else if (new Date(parsedParams.dateTo as number)?.getTime() === toDate?.getTime() && fromDate) {
            // fromDate changed
            toDate = new Date(fromDate.getTime())
            toDate.setHours(0, 0, 0, 0)
            toDate.setDate(fromDate.getDate() + maxDayDifferenceForDateRange)
          }
        }

        if (fromDate && toDate) {
          const fixedDayDifference = utilsService.getDayDifferenceFromTwoDates(fromDate.getTime(), toDate.getTime())
          const data = {
            dayDifference: fixedDayDifference,
          }

          analyticsService.trackEvent('Live Events Tracker: Date Range Day Difference Changed', {
            data,
            serviceToExclude: onlyMixpanelTrackingServicesToExclude,
          })
        }
      }

      if (parsedParams.dateFrom !== fromDate?.getTime() || parsedParams.dateTo !== toDate?.getTime()) {
        setSearchParams((currentParams) => ({ ...currentParams, dateFrom: fromDate?.getTime(), dateTo: toDate?.getTime() }))
      }

      // every time date range is changed, report it to calendar components
      setCalendarTimeRanges((currentRanges) => {
        const newRanges = { ...currentRanges }
        Object.keys(newRanges).forEach((key) => {
          newRanges[key] = { timeStart: fromDate?.getTime(), timeEnd: toDate?.getTime() }
        })

        return newRanges
      })
    },
    [parsedParams.dateFrom, parsedParams.dateTo, setSearchParams]
  )

  // make sure selected date starts from start of day and ends to end of day for feed view
  const handlePerformanceEffectThresholdChange = useCallback(
    (value: number) => {
      setSearchParams((currentParams) => ({ ...currentParams, performanceEffectThreshold: value }))
    },
    [setSearchParams]
  )

  const handlePerformanceChangesByChange = useCallback(
    (value: PerformanceChangesByOptionValue) => {
      setSearchParams((currentParams) => ({ ...currentParams, performanceChangesBy: value }))
    },
    [setSearchParams]
  )

  // set additional game datas from url params
  useEffect(() => {
    if (calendarAdditionalDatas.length > 0) {
      let datas = calendarAdditionalDatas.filter((data) => parsedParams.additionalDatas?.includes(data.id)) || []

      const calendarAdditionalDatasMap: { [dataId: string]: boolean } = {}
      datas.forEach((data) => {
        calendarAdditionalDatasMap[data.id] = true
      })

      setCommonFilters((current) => ({ ...current, additionalDatas: calendarAdditionalDatasMap }))
    }
  }, [parsedParams.additionalDatas, calendarAdditionalDatas])

  useEffect(() => {
    dispatch(visibleGameCalendarsChanged([game.id]))
  }, [dispatch, game])

  // initially set the default date range
  useEffect(() => {
    // TODO: checking hasAccessToLiveEventsTracker is unnecesary since we only show Feature Locked Card when user does not have the needed role
    const startDate = parsedParams.dateFrom ? new Date(parsedParams.dateFrom) : startOfDay(add(new Date(), { days: -14 }))
    const initialStartDate = hasAccessToLiveEventsTracker ? startDate : new Date(lockedLiveEventsTrackerStartTimestamp)
    const endDate = parsedParams.dateTo ? new Date(parsedParams.dateTo) : endOfDay(new Date())
    const initialEndDate = hasAccessToLiveEventsTracker ? endDate : new Date(lockedLiveEventsTrackerEndTimestamp)
    setSearchParams((currentParams) => ({ ...currentParams, dateFrom: initialStartDate.getTime(), dateTo: initialEndDate.getTime() }))
    setCalendarTimeRanges((currentRanges) => {
      const newRanges = { ...currentRanges }
      newRanges[game.id] = { timeStart: initialStartDate?.getTime(), timeEnd: initialEndDate?.getTime() }

      return newRanges
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="GameLiveEvents">
      {!trackedGame ? (
        <GRCircularProgress />
      ) : (
        <>
          {hasAccessToLiveEventsTracker && trackedGame.allowed === true ? (
            <>
              {
                <Box>
                  <Grid container alignItems={'center'}>
                    <Grid item xs>
                      {t('overview:live_events_help_text')}
                    </Grid>
                    <Grid item>
                      <Link to={`/live-events-tracker/games?gameIds=${game.id}`}>
                        <Button variant="contained" color="primary" size="small">
                          {t('overview:live_events_tracker_button')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 3 }} />
                  <Box mb={3}>
                    <LiveEventsFilters
                      dateRange={dateRange}
                      minDate={defaultTrackedEventsStartTimestamp}
                      maxDate={filteredTrackingEventsByGame.maxTime}
                      sortGamesBy={parsedParams.sortGamesBy}
                      threshold={parsedParams.performanceEffectThreshold}
                      performanceChangesBy={parsedParams.performanceChangesBy}
                      onDateRangeChange={handleDateRangeChange}
                      onThresholdChange={handlePerformanceEffectThresholdChange}
                      onPerformanceChangesByChange={handlePerformanceChangesByChange}
                      config={filterConfig}
                    />
                  </Box>

                  {calendarTimeRanges[trackedGame.game.id] && (
                    <LiveEventsCalendarByGameContainer
                      trackedGame={trackedGame}
                      trackedEvents={filteredTrackingEventsByGame.events[trackedGame.game.id] || emptyArray}
                      performanceEffects={filteredTrackingEventsByGame.performanceEffects[trackedGame.game.id] || emptyArray}
                      gameTopIAPs={filteredTrackingEventsByGame.gameTopIAPs[trackedGame.game.id] || emptyArray}
                      gameVersions={filteredTrackingEventsByGame.gameVersions[trackedGame.game.id] || emptyArray}
                      analystReviews={filteredTrackingEventsByGame.comments?.[trackedGame.game.id] || emptyArray}
                      calendarTimeRanges={calendarTimeRanges}
                      onItemClick={handleCalendarEventItemClick}
                      onGroupClick={handleCalendarEventGroupClick}
                      onOverviewTrackedGameChanged={handleOverviewTrackedGameChanged}
                      isLoading={trackedEvents.isLoading}
                      timeStart={calendarTimeRanges[game.id] ? calendarTimeRanges[game.id].timeStart : 0}
                      timeEnd={calendarTimeRanges[game.id] ? calendarTimeRanges[game.id].timeEnd : 0}
                      minZoom={minLiveEventsCalendarZoomInMilliseconds}
                      maxZoom={maxLiveEventsCalendarZoomInMilliseconds}
                      highlightedEventId={highlightedEventId}
                      onCalendarTimeRangeChange={handleCalendarTimeRangeChange}
                      selectedCalendarAdditionalDataIdsMap={commonFilters.additionalDatas}
                      onCalendarChange={handleCalendarRerender}
                      onGamePerformanceDialogOpen={handleGamePerformanceDialogOpen}
                    />
                  )}
                </Box>
              }

              {gamePerformanceDialogData && (
                <GamePerformanceDialog
                  open={!!gamePerformanceDialogData}
                  onClose={handlePerformanceDialogClose}
                  appId={gamePerformanceDialogData!.appId}
                  marketIso={gamePerformanceDialogData!.marketIso}
                  gameName={gamePerformanceDialogData!.gameName}
                  selectedVerticalMarks={gamePerformanceDialogSelectedUpdates}
                  initialYAxisLeftConfig={gamePerformanceDialogData.initialYAxisLeftConfig}
                  initialYAxisRightConfig={gamePerformanceDialogData.initialYAxisRightConfig}
                  initialGranularity={gamePerformanceDialogData.initialGranularity}
                  onChartClick={handleChartClick}
                  render={(granularity: GranularityValue) => {
                    return (
                      <LiveEventInstanceTableContainer
                        interval={getPerformanceDialogInstanceTableInterval(granularity)}
                        events={trackedEvents.events}
                        trackedGames={trackedGameArray}
                        appId={gamePerformanceDialogData.appId}
                        isLoading={isLoadingTrackedGame || trackedEvents.isLoading}
                      />
                    )
                  }}
                ></GamePerformanceDialog>
              )}
            </>
          ) : (
            <LockedFeature.Card lockedFeatureId={LockedFeatureId.LiveEventsTracker} />
          )}
        </>
      )}
    </div>
  )
}

export default GameLiveEvents
