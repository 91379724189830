import { useCallback, useEffect } from 'react'

import analyticsService from '../../../services/AnalyticsService'
import languageService from '../../../services/LanguageService'
import { AnalyticsProviders } from '../../../types/IAnalyticsProvider'
import { DescriptionTabImageListingType } from '../components/LiveEventsEventDialog/DescriptionTab/DescriptionTab'
import { LiveEventTrackerTab } from '../types/LiveEventTrackerTab'
import { LiveEventsCommonFilterGroup, LiveEventsCommonFilterKeys, LiveEventsCommonFiltersSelection } from '../types/LiveEventsCommonFilters'
import { PerformanceChangesByOptionValue } from '../types/PerformanceChangesByOption'
import { TrackedGame } from '../types/TrackedGame'

export const onlyMixpanelTrackingServicesToExclude = [AnalyticsProviders.hubspot, AnalyticsProviders.intercom]

export const useLiveEventsTrackerPageAnalyticsEvents = (
  subpage: LiveEventTrackerTab,
  selectedGames: TrackedGame[],
  performanceChangesBy: PerformanceChangesByOptionValue,
  eventNameSearchValue: string
) => {
  // track analytics event for selected tab
  useEffect(() => {
    const data = {
      tab: subpage,
    }

    analyticsService.trackEvent('Live Events Tracker: Selected Tab', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [subpage])

  // track analytics event for selected games
  useEffect(() => {
    if (selectedGames.length > 0) {
      selectedGames.forEach((trackedGame) => {
        const gameData = {
          gameName: trackedGame.game.names['us'] || trackedGame.game.name,
          appId: trackedGame.game.appId,
          conventionalSubgenre: languageService.getTranslation('conventionalSubgenres', trackedGame.game.conventionalSubgenreId),
        }
        analyticsService.trackEvent('Live Events Tracker: Selected Game', {
          data: gameData,
          serviceToExclude: onlyMixpanelTrackingServicesToExclude,
        })
      })

      analyticsService.trackEvent('Live Events Tracker: Selected Games Count', {
        data: { gamesCount: selectedGames.length },
        serviceToExclude: onlyMixpanelTrackingServicesToExclude,
      })
    }
  }, [selectedGames])

  // track analytics event for performance changes by
  useEffect(() => {
    const data = {
      value: performanceChangesBy,
    }

    analyticsService.trackEvent('Live Events Tracker: Perfomance Changes By Changed', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [performanceChangesBy])

  useEffect(() => {
    if (eventNameSearchValue) {
      const data = {
        search: eventNameSearchValue,
      }

      analyticsService.trackEvent('Live Events Tracker: Filtered Events by Event Name', {
        data,
        serviceToExclude: onlyMixpanelTrackingServicesToExclude,
      })
    }
  }, [eventNameSearchValue])
}

export const useLiveEventDialogAnalyticsEvents = (
  name?: string,
  appId?: number,
  eventId?: string,
  eventName?: string,
  eventTypeId?: string,
  eventTypeName?: string,
  tab?: string
) => {
  // track analytics event for live event dialog data change
  useEffect(() => {
    if (!name || !eventName || !eventTypeId) return

    const data = {
      name,
      appId,
      eventId,
      eventName,
      eventTypeId,
      eventTypeName,
    }

    analyticsService.trackEvent('Live Events Tracker: Opened Game Live Event Dialog', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [name, appId, eventId, eventName, eventTypeId, eventTypeName])

  // track analytics event for live event dialog tab change
  useEffect(() => {
    const data = {
      tab,
    }

    analyticsService.trackEvent('Live Events Tracker / Event Dialog: Selected Tab', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [tab])
}

type ImageListingTypeChangeEventData = {
  imageListingType: DescriptionTabImageListingType
  trackedGame?: TrackedGame
  eventId?: string
  eventName?: string
  eventTypeId?: string
  eventTypeName?: string
}

export const useLiveEventDialogDescriptionTabAnalyticsEvents = () => {
  const handleTrackImageListingTypeChangeEvent = useCallback(
    ({ imageListingType, trackedGame, eventId, eventName, eventTypeId, eventTypeName }: ImageListingTypeChangeEventData) => {
      const data = {
        type: imageListingType,
        gameAppId: trackedGame?.game.appId,
        gameName: trackedGame?.game.names['us'] || trackedGame?.game.name,
        eventId,
        eventName,
        eventTypeId,
        eventTypeName,
      }

      analyticsService.trackEvent('Live Events Tracker / Event Dialog: Changed Screenshots Listing Type', {
        data,
        serviceToExclude: onlyMixpanelTrackingServicesToExclude,
      })
    },
    []
  )

  return {
    handleTrackImageListingTypeChangeEvent,
  }
}

export const useLiveEventsCommonFiltersAnalyticsEvents = () => {
  const handleTrackCommonFilterGroupSelectAllEvent = useCallback((commonFilterGroupKey: LiveEventsCommonFilterKeys) => {
    const data = {
      id: commonFilterGroupIds[commonFilterGroupKey],
    }

    analyticsService.trackEvent('Live Events Tracker: Selected All Filters', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [])

  const handleTrackFilterChangedEvent = useCallback(({ id, name, type }: LiveEventsCommonFiltersSelection) => {
    const data = {
      id,
      name,
      type,
    }

    analyticsService.trackEvent('Live Events Tracker: Selected Filter', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [])

  return {
    handleTrackCommonFilterGroupSelectAllEvent,
    handleTrackFilterChangedEvent,
  }
}

const commonFilterGroupIds = {
  [LiveEventsCommonFilterKeys.AdditionalDatas]: LiveEventsCommonFilterGroup.GameData,
  [LiveEventsCommonFilterKeys.LiveEventAppearances]: LiveEventsCommonFilterGroup.Appearance,
  [LiveEventsCommonFilterKeys.LiveEventDurations]: LiveEventsCommonFilterGroup.Duration,
  [LiveEventsCommonFilterKeys.LiveEventTags]: LiveEventsCommonFilterGroup.EventType,
  [LiveEventsCommonFilterKeys.LiveEventSecondaryTags]: LiveEventsCommonFilterGroup.EventSecondaryTags,
  [LiveEventsCommonFilterKeys.Motivations]: LiveEventsCommonFilterGroup.Motivation,
}
